.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.app-wrapper {
  padding: 1em 10px 10px 1em;
}

.app-contentLayout {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.app-contentLayout > .app-contact > h1,
.app-contentLayout > .app-about > h1,
.app-contentLayout > .app-contact > h2,
.app-contentLayout > .app-about > h2,
.app-contentLayout > .app-contact > h3,
.app-contentLayout > .app-about > h3,
.app-contentLayout > .app-contact > h4,
.app-contentLayout > .app-about > h4 {
  margin: 0 auto 0;
  display: block;
  padding: 0;
  width: 100%;
  text-align: center;
}

.app-contentLayout > p {
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #09d3ac;
}

.btn-resume:hover {
  color: chartreuse;
  background-color: #282c34;
  border-color: chartreuse;
}
