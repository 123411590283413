.navbar-wrapper {
    display: block;
}

.navbar-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

.navbar-list > li {
    display: inline;
}

.navbar-list > li > a {
    text-decoration: none;
    display: inline-block;
    padding: 8px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
}

.navbar-list > li > a:link,
.navbar-list > li > a:visited{
    color: white;
}

.navbar-list > li > a:hover {
    background-color: green;
}
